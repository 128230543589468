<template>
  <div>
    <div class="swiper">
      <div class="swiper-container">
        <div class="swiper-wrapper">

          <div class="swiper-slide">
            <img src="https://qiniu.youjiamall.cn/yjpc/banner333.png" alt="">
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
      <div class="about_top">
          <subTitle en-tit="PRODUCT CUSTOMIZATION" zh-tit="产品定制"></subTitle>
          <div class="about_mai">
            <ul class="mai_ul">
              <li v-for="(item,index) in navList" :key="index"
              :class="{active:current === index}"
              @mousemove="navClick(index)">
                <img v-if="current === index" :src="item.selIcon" alt="">
                <img v-else :src="item.icon" alt="">
                <p>{{item.name}}</p>
              </li>
            </ul>
            <ul class="about_box" v-if="current === 0">
              <li>
                <img src="https://qiniu.youjiamall.cn/dy_card222.png" alt="">
                <p>优加电影卡</p>
              </li>
              <li>
                <img src="https://qiniu.youjiamall.cn/dy_card444.png" alt="">
                <p>优加电影券</p>
              </li>
              <li>
                  <img src="https://qiniu.youjiamall.cn/dy_card333.png" alt="">
                <p>优加VIP电影卡</p>
              </li>
              <li>
                <img src="https://qiniu.youjiamall.cn/dy_card555.png" alt="">
                <p>优加VIP电影券</p>
              </li>
              <li>
                <img src="https://qiniu.youjiamall.cn/dy_card111.png" alt="">
                <p>优加电影次票</p>
              </li>

            </ul>

            <ul class="about_box" style="justify-content: center" v-if="current === 1">
              <li>
                <img src="https://qiniu.youjiamall.cn/yjpc/cake_card.png" alt="">
                <p>优加蛋糕券</p>
              </li>
            </ul>            

            <ul class="about_box" style="justify-content: center" v-if="current === 3">
              <li style="margin-right: 40px;">
                <img src="https://qiniu.youjiamall.cn/yjpc/qfcard.png" alt="">
                <p>优加全福券</p>
              </li>
              <li>
                <img src="https://qiniu.youjiamall.cn/yjpc/thcard.png" alt="">
                <p>优加提货券</p>
              </li>
            </ul>

            <ul class="about_box" style="justify-content: center" v-if="current === 2">
              <li style="margin-right: 100px;">
                <img src="https://qiniu.youjiamall.cn/tslx22.png" alt="">
                <p>图书卡</p>
              </li>
              <li>
                <img src="https://qiniu.youjiamall.cn/tslx33.png" alt="">
                <p>图书券</p>
              </li>
            </ul>

            <div class="about_form">
              <div class="form_input">
                <form ref="form" @submit.prevent="sendEmail">
                  <input type="text"  v-model="params.username" name="name" placeholder="请输入您的姓名">
                  <input type="text"  v-model="params.companyName"  name="CompanyName" placeholder="请输入您公司名称">
                  <input type="text"  v-model="params.phone" maxlength="11" name="Phone" placeholder="请输入联系电话">
                  <div class="form_codeBox">
                    <input type="text" v-model="identifyCode" placeholder="请输入验证码">
                    <VerifyCode class="pd-t-6" v-model:changeCode="identifyCode" contentHeight="60" />
                  </div>
                  <input v-if="!isDbtn" type="submit" class="form_sub" value="立即预约">
                </form>
                <button v-if="isDbtn"  disabled class="form_sub disable">立即预约</button>
              </div>
            </div>
          </div>
      </div>

      <div class="about_map">
        <subTitle en-tit="COMPANY ADDRESS" zh-tit="公司地址"></subTitle>
        <div class="map_box">
          <ul class="map_card">
            <li>
              <p><span>服务热线</span></p>
              <span>400-700-1314</span>
            </li>
            <li>
              <p><span>服务时间</span></p>
              <span>09:00-21：00</span>
            </li>
            <li>
              <p><span>商务合作</span></p>
              <span>bd@youjiahuipin.com</span>
            </li>
          </ul>
          <gdmap></gdmap>
        </div>
      </div>
  </div>
</template>

<script>
import subTitle from "@/components/subTitle";
import emailjs from "@emailjs/browser";
import VerifyCode from "@/components/VerifyCode";
import gdmap from "@/components/gdmap";
export default {
  name: "callMe",
  components:{
    subTitle,
    VerifyCode,
    gdmap
  },
  metaInfo () {
    return {
      title:"优加惠品",
      meta: [{
        name: 'keyWords',
        content: '优加惠品,福利,年节慰问品,电影,蛋糕福利'
      },
        {
          name: 'description',
          content: '优加惠品,优加,福利,慰问品,年节慰问品,电影,提货券,蛋糕卡,电影卡,生日卡,生日券,蛋糕券,全福卡,全福券,蛋糕,电影福利,蛋糕福利,元宵节慰问品,龙抬头慰问品,端午慰问品节,七夕节慰问品,三八妇女节,五一劳动节,中秋节慰问品,重阳节慰问品,下元节慰问品,冬至节慰问品,腊八节慰问品,小年慰问品,除夕慰问品,春节慰问品,元宵节,龙抬头,端午节,七夕节,中秋节,重阳节,三八妇女节,五一劳动节,下元节,冬至节,腊八节,小年,员工福利,员工体检,企业团险,员工团建活动,夏送清凉,冬送温暖'
        }],
      link: [{ // set link
        rel: 'asstes',
        href: 'https://assets-cdn.github.com/'
      }]
    }
  },
  data() {
    return {
      identifyCode:"",
      params:{
        companyName:"",
        username:"",
        // CompanyNumber:"",
        phone:""
      },
      isDbtn:false,
      current:0,
      navList:[{
        name:'电影',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/dianying-1.png',
        icon:'https://qiniu.youjiamall.cn/yjpc/dianying.png'
      },{
        name:'蛋糕',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/dangao-1.png',
        icon:'https://qiniu.youjiamall.cn/yjpc/dangao.png'
      },{
        name:'图书',
        selIcon:'https://qiniu.youjiamall.cn/tslxicon33.png',
        icon:'https://qiniu.youjiamall.cn/txlsicon22.png'
      },{
        name:'年节慰问',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/nianjie-1.png',
        icon:'https://qiniu.youjiamall.cn/yjpc/nianjie.png'
      }]
    }
  },
  methods:{
    sendEmail() {
      if(this.params.companyName == "" || this.params.username == "" || this.params.phone == "" ) {
        alert('为更好的为您服务，请您完善相关信息~')
        return false;
      }
      if(this.params.phone.length != 11) {
        alert('您的手机号码格式有误！');
        return false;
      }
      if(this.identifyCode.toUpperCase() != this.$children[1].identifyCode.toUpperCase()) {
        alert('您的验证码有误~')
        return false;
      }
      this.isDbtn = true;
      emailjs.sendForm('service_ran5c65', 'template_mbh26am', this.$refs.form, 'cqFIomNLTWlpmUMFQ')
          .then((result) => {
            this.isDbtn = false;
            alert('预约成功！'+'\n'+'我们会在24小时内给您回电')
            this.identifyCode = '';
          }, (error) => {
            this.isDbtn = false;
            this.identifyCode = '';
            alert(error.text)
          });
    },
    navClick(index) {
      this.current = index;
    }
  }

}
</script>

<style scoped>
/*.swiper {*/
/*  background: #fff;*/
/*}*/
/*.swiper img {*/
/*  object-fit: cover;*/
/*}*/
  .about_top {
    padding-top: 54px;
    box-sizing: border-box;
  }
  .about_mai {
    margin: 55px 120px;
    box-sizing: border-box;
    box-shadow: 0px 4px 16px 0px rgba(189,189,189,0.35);
    height: 727px;
  }
  .about_mai .mai_ul {
    display: flex;
    justify-content: space-between;
  }
  .mai_ul li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height: 136px;
    width: 100%;
    margin-right: 24px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .mai_ul li img {
    width: 44px;
    height: auto;
    margin-bottom: 14px;
  }
.mai_ul li:nth-child(4) img {
  width: 36px;
}
.mai_ul li:nth-child(3) img {
  width: 40px;
}
  .mai_ul .active {
    color: #fff;
    background: url("https://qiniu.youjiamall.cn/yjpc/movie_bg1.png")no-repeat;
    background-size: 100% 100%;
  }
.mai_ul .active:nth-child(2) {
  background: url("https://qiniu.youjiamall.cn/yjpc/movie_bg2.png")no-repeat;
  background-size: 100% 100%;
}
.mai_ul .active:nth-child(3) {
  background: url("https://qiniu.youjiamall.cn/tslx.png")no-repeat;
  background-size: 100% 100%;
}
.mai_ul .active:nth-child(4) {
  background: url("https://qiniu.youjiamall.cn/yjpc/movie_bg3.png")no-repeat;
  background-size: 100% 100%;
}
  .about_box {
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 30px 145px;
    box-sizing: border-box;
    font-size: 12px;
  }

  .about_box img {
    /*width: 130px;*/
    height: 77px;
    margin-bottom: 15px;
  }
  .about_form {
    width: 100%;
  }
  .about_form p {
    text-align: center;
  }
  .about_form input {
    width: 644px;
    height: 50px;
    background: #F9F9F9;
    border-radius: 9px;
    outline: none;
    border: 0;
    padding: 0 25px;
    box-sizing: border-box;
    margin-bottom: 19px;
  }
  .about_form .btn {
    width: 360px;
    height: 50px;
    background: #007858;
    border-radius: 9px;
    text-align: center;
    line-height: 50px;
    color: #fff;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    margin:30px auto;
  }

  .map_box {
    margin-top: 48px;
    width: 100%;
    /*padding-left: 148px;*/
    box-sizing: border-box;
    /*height: 684px;*/
    position: relative;
    background: #fff;
  }

  .map_card {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    width: 381px;
    padding: 64px 0 50px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 8px 14px 0px rgba(189,189,189,0.35);
    border-radius: 60px 0px 60px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .map_card li {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-bottom: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .map_card p {
    position: relative;
    border-bottom: 5px solid #007858;
    width: 70px;
    margin-bottom: 5px;
  }
  .map_card p>span {
    position: absolute;
    bottom: -6px;
  }
  .map_card li span {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
  }

  .map_bg {
    width: 100%;
    height: 554px;
    background: url(../assets/img/map.png)no-repeat;
    background-size: 100% auto;
    /*background-position: 100% 100%;*/
    /*background-image: linear-gradient(to right, rgba(255, 255, 255,1), rgba(255, 255, 255, 0)),url(../assets/img/map.png);*/
    /*background-repeat: no-repeat;*/
  }
.form_input .form_sub {
  border: 0;
  outline: none;
  cursor: pointer;
  font-weight: 400;
  width: 360px;
  height: 50px;
  background: #007858;
  border-radius: 9px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  margin:30px auto;
}

.form_input p {
  color: #fff;
  margin-bottom: 13px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}
.form_input form {
  display:flex;
  flex-direction: column;
  align-items: center;
}
.form_input {
  display:flex;
  flex-direction: column;
}

.form_input input {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
}

.form_code {
  display: flex;
  align-items: center;
}

.form_code input {
  width: 148px;
  margin-right: 10px;
}
.form_codeBox {
  display: flex;
  width: 640px;
}
.form_codeBox input{
  width: 100%;
  display: inline-block;
  margin-right: 30px;
}
.pd-t-6 {
  /*margin-top: 4px;*/
}
</style>
