import { render, staticRenderFns } from "./aboutUs.vue?vue&type=template&id=5d37b34c&scoped=true&"
import script from "./aboutUs.vue?vue&type=script&lang=js&"
export * from "./aboutUs.vue?vue&type=script&lang=js&"
import style0 from "./aboutUs.vue?vue&type=style&index=0&id=5d37b34c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d37b34c",
  null
  
)

export default component.exports